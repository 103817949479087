@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .-rotate-x-90 {
    transform: rotateX(-90deg);
  }

  .disc-list,
  .note-list,
  .square-list {
    counter-reset: foo;
    display: table;
  }

  .disc-list > li,
  .note-list > li,
  .square-list > li {
    counter-increment: foo;
    display: table-row;
  }

  .disc-list > li::before,
  .note-list > li::before,
  .square-list > li::before {
    display: table-cell;
    text-align: right;
  }

  .square-list > li::before {
    content: "■";
  }

  .note-list > li::before {
    content: "※";
  }

  .disc-list > li::before {
    content: counter(foo, disc) "";
  }
  
  .card-front, .card-back {
    backface-visibility: hidden;
  }
  
  .card-back {
    transform: rotateX(.5turn);
  }
  
  .card {
    transform-style: preserve-3d;
  }
  .card:hover {
    transform: rotateX(.5turn);
  }
}

@layer components {
  .new-content {
    @apply leading-loose;
  }

  .new-content p {
    @apply mb-5;
  }

  .notification-content h2 {
    @apply font-bold text-sm;
  }

  .notification-content p {
    @apply text-sm;
  }

  .notification-content a {
    @apply font-bold text-primary-dark;
  }
  
  .card-front, .card-back {
    @apply flex flex-col items-start gap-2 md:gap-8;
  }
}

@layer base {
  :root {
    --color-rank-primary: rgb(250 239 224);
    --color-rank-secondary: rgb(207 235 233);
    --color-rank-secondary-dark: rgb(61 157 154);
    --color-rank-primary-dark: rgb(242 146 28);
    --color-rank-tertiary: rgb(129 83 26);
    --color-rank-primary-accent: rgb(242 146 28);
    --color-rank-secondary-accent: rgb(61 157 154);
    --color-rank-bg: rgb(250 239 224);
    --color-rank-bg-secondary: rgb(207 235 233);
    --color-rank-bg-tertiary: rgb(239 227 213);
    --color-rank-tertiary-accent: rgb(129 83 26);
    --color-rank-secondary-accent-light: rgb(129 83 26);
    --color-rank-tertiary-text: rgb(129 83 26);
    --color-rank-secondary-text: rgb(61 157 154);
  }
  html:has(.anchor-scroll) {
    @apply scroll-smooth;
  }

  body {
    @apply bg-[#F9F9F3] text-primary-text;
  }

  input::placeholder {
    @apply text-gray-200;
  }

  input:-ms-input-placeholder {
    @apply text-gray-200;
  }

  input::-ms-input-placeholder {
    @apply text-gray-200;
  }

  svg > g > g:last-child {
    pointer-events: none
  }
}